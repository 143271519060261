import "../styles/About.css";

import React from "react";

import Container from "react-bootstrap/Container";

function About() {
	return (
		<section className="about-section bg-light">
			<Container className="text-center">
				<h2 className="mb-4">Tietoa minusta</h2>
				<p>
					Mun nimi on Akseli ja mä striimaan Pelejä ja juttelu striimejä
					harrastusmielssä. Oon striimannu 3 vuotta, mutta nyt 0,5 vuotta
					aktiivisesti. Oon kiinnostunut maataloudesta, pelien ja 3d
					mallinnusten tekemisestä. Tykkään eniten pelata minecraft ja gta rp.
				</p>
			</Container>
		</section>
	);
}

export default About;
