import "../styles/Gallery.css";

import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import profiilikuva from "../assets/profiilikuva.png";
import streamjatkuu from "../assets/streamjatkuu.png";
import streamalkaa from "../assets/streamalkaa.png";

function Gallery() {
	return (
		<section className="gallery-section bg-light">
			<Container className="text-center">
				<h2 className="mb-4">Galleria</h2>
				<Row>
					<GalleryPhoto image={profiilikuva} text="Profiilikuva" />
					<GalleryPhoto image={streamjatkuu} text="Stream jatkuu kohta" />
					<GalleryPhoto image={streamalkaa} text="Stream alkaa kohta" />
				</Row>
			</Container>
		</section>
	);
}

function GalleryPhoto(props: any) {
	return (
		<Col md={4}>
			<Image src={props.image} rounded fluid />
			<h4>{props.text}</h4>
		</Col>
	);
}

export default Gallery;
