import React from "react";

import Footer from "./components/Footer";

import Header from "./components/Header";
import About from "./components/About";
import Gallery from "./components/Gallery";
import Twitch from "./components/Twitch";
import Links from "./components/Links";

function App() {
	return (
		<>
			<Header />
			<About />
			<Gallery />
			<Twitch />
			<Links />
			<Footer />
		</>
	);
}

export default App;
