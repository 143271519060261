import "../styles/Header.css";

import React from "react";

import Container from "react-bootstrap/Container";

function Header() {
	return (
		<header className="masthead">
			<Container className="d-flex h-100 align-items-center">
				<div className="mx-auto text-center">
					<h1 className="mx-auto my-0 text-uppercase">Auksel</h1>
					<h2 className="mx-auto mt-2 mb-5 text-white">
						Se on Aukselii tässä moi. Täältä löydät tietoa musta, mun
						striimeistä ja saatat törmätä johonkin muuhun tietoon kanssa 🙂
					</h2>
				</div>
			</Container>
		</header>
	);
}

export default Header;
