import "../styles/Links.css";

import React from "react";

import Container from "react-bootstrap/Container";

function Links() {
	return (
		<section className="links-section bg-light">
			<Container className="text-center">
				<h2 className="mb-4">Linkkejä</h2>
				<p>
					<a href="https://auksel.net/discord">Discord</a>
				</p>

				<p>
					<a href="https://www.twitch.tv/aukselii">Twitch</a>
				</p>

				<p>
					<a href="https://finprison.net/discord">Finprisonin Discord</a>
				</p>

				<p>
					<a href="https://shop.spreadshirt.fi/aukseliishop/">Aukselii Merch</a>
				</p>
			</Container>
		</section>
	);
}

export default Links;
