import "../styles/Twitch.css";

import React from "react";

import Container from "react-bootstrap/Container";

function Twitch() {
	return (
		<section className="twitch-section bg-light">
			<Container className="text-center">
				<h2 className="mb-4">Twitch seuraajat</h2>
				<iframe
					title="Twitch seuraajat"
					height="100px"
					width="350px"
					frameBorder="0"
					src="https://livecounts.org/twitch-follower-count/Aukselii"
				></iframe>
			</Container>
		</section>
	);
}

export default Twitch;
