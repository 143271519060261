import React from "react";

import Container from "react-bootstrap/Container";

function Footer() {
	return (
		<footer className="footer text-light">
			<Container>Auksel.net 2021</Container>
		</footer>
	);
}

export default Footer;
